import Mission from 'components/mission/Mission';
import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

const Home = lazy(() => import('components/home/Home'));
const Footer = lazy(() => import('partials/footer/Footer'));
const About = lazy(() => import('components/about/About'));
const Tokens = lazy(() => import('components/tokens/Tokens'));
const RoadMap = lazy(() => import('components/roadmap/RoadMap'));
const Team = lazy(() => import('components/team/Team'));
const Nfts = lazy(() => import('components/nfts/Nfts'));
const Explore = lazy(() => import('components/explore/Explore'));
const WhitePaper = lazy(() => import('components/whitePaper/WhitePaper'));
const Faq = lazy(() => import('components/faq/Faq'));
const PrivacyPolicy = lazy(() =>
  import('components/privacyPolicy/PrivacyPolicy')
);

const useRoutesHook = () => {
  let element = useRoutes([
    { path: 'our-story', element: <About /> },
    { path: 'nfts', element: <Nfts /> },
    { path: 'token', element: <Tokens /> },
    { path: 'roadmap', element: <RoadMap /> },
    { path: 'team', element: <Team /> },
    { path: 'contact', element: <Footer /> },
    { path: 'white-paper', element: <WhitePaper /> },
    { path: 'mission', element: <Mission /> },
    { path: 'explore', element: <Explore /> },
    { path: 'faq', element: <Faq /> },
    { path: 'privacy-policy', element: <PrivacyPolicy /> },
    { path: '*', element: <Home /> },
  ]);

  return element;
};

export default useRoutesHook;
