import React, { Suspense } from 'react';
import useRoutesHook from 'routes/routes';
import Header from 'partials/header/Header';

function App() {
  const routes = useRoutesHook();
  return (
    <div className='landingPage DBlock'>
      <Header />
      <Suspense
        fallback={
          <div className='loading'>
            <div className='spinner'></div>
          </div>
        }>
        {routes}
      </Suspense>
    </div>
  );
}

export default App;
