import React, { useEffect } from 'react';

const HOCComponent =
  (Children) =>
  ({ props }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      let navExpand = document.getElementsByClassName('navbar-collapse');
      let navBtn = document.getElementsByClassName('navbar-toggler');
      if (navExpand[0]?.classList.contains('show')) navBtn[0]?.click();
    }, []);
    return <Children {...props} />;
  };

export default HOCComponent;
