import { useState } from 'react';
import { Facebook, Instagram, Menu, Twitter } from 'react-feather';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import { navLinks } from 'helpers/helper';

const Header = () => {
  const [collapseOnSelect, setCollapseOnSelect] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  return (
    <header id='home'>
      <Navbar
        className={pathname === '/explore' ? 'tabHeader' : ''}
        expand='xl'
        collapseOnSelect={collapseOnSelect}>
        <Container>
          <NavLink to='/'>
            <Navbar.Brand>
              <img src='/assets/images/logo.png' alt='Logo' />
            </Navbar.Brand>
          </NavLink>
          <Navbar.Toggle>
            <Menu size={28} color='white' />
          </Navbar.Toggle>
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='mx-auto my-auto'>
              {navLinks?.map((link) => (
                <NavLink
                  to={link?.linkUrl}
                  key={link?.id}
                  activeclassname='active'
                  className='headerLinks'
                  onClick={() => setCollapseOnSelect(true)}>
                  {link?.text}
                </NavLink>
              ))}
            </Nav>
            <ul className='socialLinks DFlex justify-content-start w-auto'>
              <li>
                <a
                  href='https://twitter.com/dagameio'
                  target='_blank'
                  rel='noreferrer'>
                  <Twitter size={18} />
                </a>
              </li>
              <li>
                <a href='https://www.facebook.com/dagamellc' rel='noreferrer'>
                  <Facebook size={18} />
                </a>
              </li>
              <li>
                <a href='https://www.instagram.com/dagame.io/' rel='noreferrer'>
                  <Instagram size={18} />
                </a>
              </li>
              <li>
                <a
                  href='https://discord.gg/dagame'
                  target='_blank'
                  rel='noreferrer'>
                  <svg
                    width='17'
                    height='13'
                    viewBox='0 0 17 13'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M10.713 0C10.5475 0.296406 10.4 0.602516 10.2713 0.916667C9.00694 0.716709 7.719 0.716709 6.45463 0.916667C6.32596 0.602516 6.17847 0.296406 6.01297 0C4.82196 0.203501 3.66414 0.567948 2.5713 1.08333C0.617085 3.90357 -0.269168 7.32746 0.0713014 10.7417C1.34483 11.699 2.77418 12.4292 4.2963 12.9C4.6429 12.4422 4.95222 11.9573 5.2213 11.45C4.7247 11.2669 4.24738 11.0352 3.7963 10.7583C3.92002 10.676 4.03705 10.5841 4.1463 10.4833C5.46168 11.1169 6.90294 11.446 8.36297 11.446C9.82299 11.446 11.2642 11.1169 12.5796 10.4833C12.6963 10.5833 12.813 10.675 12.9296 10.7583C12.4761 11.033 11.9993 11.2673 11.5046 11.4583C11.7626 11.9771 12.0636 12.4733 12.4046 12.9417C13.9248 12.4725 15.3517 11.7422 16.6213 10.7833C16.9699 7.3685 16.083 3.94182 14.1213 1.125C13.0407 0.598948 11.8944 0.220559 10.713 0ZM5.5963 8.79167C5.17929 8.76173 4.79009 8.5714 4.5104 8.26064C4.23072 7.94988 4.0823 7.54285 4.0963 7.125C4.08018 6.7066 4.22787 6.2984 4.50798 5.98717C4.78808 5.67595 5.17852 5.48623 5.5963 5.45833C6.01408 5.48623 6.40452 5.67595 6.68462 5.98717C6.96473 6.2984 7.11242 6.7066 7.0963 7.125C7.11242 7.5434 6.96473 7.9516 6.68462 8.26282C6.40452 8.57405 6.01408 8.76377 5.5963 8.79167ZM11.1296 8.79167C10.7126 8.76173 10.3234 8.5714 10.0437 8.26064C9.76405 7.94988 9.61563 7.54285 9.62963 7.125C9.61352 6.7066 9.76121 6.2984 10.0413 5.98717C10.3214 5.67595 10.7119 5.48623 11.1296 5.45833C11.5482 5.48415 11.9399 5.67325 12.2204 5.98496C12.501 6.29666 12.6479 6.70604 12.6296 7.125C12.6479 7.54396 12.501 7.95334 12.2204 8.26504C11.9399 8.57675 11.5482 8.76585 11.1296 8.79167Z'
                      fill='#FFB600'
                    />
                  </svg>
                </a>
              </li>
              {/* <li className='btnLi'>
                <a href='https://google.com/' target='_blank' rel='noreferrer'>
                  Link Your Wallet
                </a>
              </li> */}
            </ul>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
