import HOCComponent from 'hoc/HOCComponent';
import { Col, Row, Container } from 'react-bootstrap';
import { Globe } from 'react-feather';
import { Fade, Zoom } from 'react-reveal';

const Mission = () => {
  return (
    <section
      className='missionSec DBlock'
      style={{
        background:
          'url("/assets/images/mission/missionBgImg.png") no-repeat top/cover',
      }}>
      <Container>
        <div className='missionWrapper'>
          <Zoom>
            <h1>mission</h1>
          </Zoom>
          <Row className='mt-3 missionContentSec'>
            <Col md={{ span: 8 }} className='missionDetails mt-5'>
              <Fade left>
                <ul className='contentSec leftContent'>
                  <li>
                    The co-founders of Da Game LLC are passionate about social
                    justice are dedicated to engaging with those affecting by
                    the war on drugs. A percentage of the NFT Profits will be
                    allocated for organizations and programs that reduce unjust
                    incarcerations, assist persons currently incarcerated, and
                    those transitioning out of incarcerations stemming from drug
                    related crimes.
                  </li>
                </ul>
              </Fade>
              <Fade left>
                <div className='linkSecWrapper DFlex justify-content-start'>
                  <ul className='contentSec mt-5'>
                    <li>
                      <a
                        href='https://legalequalizer.com/'
                        target='_blank'
                        rel='noreferrer'
                        className='DFlex m-2'>
                        Legal Equalizer
                        <Globe color='#699BF7' />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://worthrises.org/'
                        target='_blank'
                        rel='noreferrer'
                        className='DFlex m-2'>
                        Worth Rises
                        <Globe color='#699BF7' />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://careerkarma.com/'
                        target='_blank'
                        rel='noreferrer'
                        className='DFlex m-2'>
                        Career Karma
                        <Globe color='#699BF7' />
                      </a>
                    </li>
                  </ul>
                </div>
              </Fade>
              <Fade left>
                <ul className='contentSec leftContent my-5'>
                  <li>
                    We will also invest a portion of the treasury into{' '}
                    <a
                      href='https://freewaylite.aubit.io/ref/II57744528'
                      target='_blank'
                      rel='noreferrer'
                      className='freeWay'>
                      freeway Prime
                    </a>
                    . The returns will ne reinvested into the growth of the
                    project and the continued support of our mission.
                  </li>
                </ul>
              </Fade>
              <Fade left>
                <ul className='contentSec leftContent'>
                  <li>
                    Da Game LLC will invest a portion of the treasury into
                    Freeway Prime{' '}
                    {/* (hyperlink{' '}
                   <a
                      href='https://freewaylite.aubit.io/ref/II57744528'
                      rel='noopener noreferrer'
                      target='_blank'> 
                    <span className='link'>
                      https://freewaylite.aubit.io/ref/II57744528
                    </span>
                    </a>
                    ) */}
                    . The returns will be reinvested into thE growth of the
                    project and the continued support of our mission.
                  </li>
                </ul>
              </Fade>
            </Col>
            <Col md={{ span: 4 }} className='missionDetails mt-5'>
              <Fade bottom>
                <img
                  src='/assets/images/mission/missionImg1.png'
                  alt='img1'
                  className='d-block max-auto'
                />
              </Fade>
              <Fade bottom>
                <img
                  src='/assets/images/mission/missionImg2.png'
                  alt='img2'
                  className='d-block max-auto'
                />
              </Fade>
              <Fade bottom>
                <img
                  src='/assets/images/mission/missionImg4.png'
                  alt='img4'
                  className='d-block max-auto'
                />
              </Fade>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default HOCComponent(Mission);
