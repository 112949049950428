export const navLinks = [
  { id: 1, text: 'Home', linkUrl: '/' },
  { id: 2, text: 'Our Story', linkUrl: 'our-story' },
  { id: 3, text: "Nft's", linkUrl: 'nfts' },
  { id: 4, text: 'Token', linkUrl: 'token' },
  { id: 5, text: 'Roadmap', linkUrl: 'roadmap' },
  { id: 6, text: 'Team', linkUrl: 'team' },
  { id: 7, text: 'Mission', linkUrl: 'mission' },
  { id: 8, text: 'Contact', linkUrl: 'contact' },
  { id: 9, text: 'Faq', linkUrl: 'faq' },
];

export const teamData = [
  {
    id: 1,
    name: 't. “gustavo” Horace',
    designation: 'Co-Founder',
    image: '/assets/images/team/teamImg1.svg',
    logo: '/assets/images/team/instagram.svg',
    socialLink: 'https://www.instagram.com/thousandways2grind/',
  },
  {
    id: 2,
    name: 'J. FORD',
    designation: 'Co-Founder',
    image: '/assets/images/team/fordImg.svg',
    logo: '/assets/images/team/instagram.svg',
    socialLink: 'https://www.instagram.com/jaford33/',
  },
  {
    id: 3,
    name: 'javar rockamore',
    designation: 'Advisor/Music Producer',
    image: '/assets/images/team/teamImg2.svg',
    logo: '/assets/images/team/instagram.svg',
    socialLink: 'https://www.instagram.com/javarsaidpickupthatbag/',
  },
  {
    id: 4,
    name: 'MBYE Njie',
    designation: 'Advisor/ CEO',
    image: '/assets/images/team/teamImg4.svg',
    logo: '/assets/images/team/instagram.svg',
    socialLink: 'https://www.instagram.com/gambianchampion/',
  },
  // {
  //   id: 5,
  //   name: 'DANIELLE MURRAY',
  //   designation: 'Content Manager',
  //   image: '/assets/images/team/teamImg5.svg',
  //   logo: '/assets/images/team/instagram.svg',
  //   socialLink: 'https://www.instagram.com/humblenatureconsulting/',
  // },
  // {
  //   id: 6,
  //   name: 'Supreme Luci',
  //   designation: 'Director of Events ',
  //   image: '/assets/images/team/teamImg6.svg',
  //   logo: '/assets/images/team/instagram.svg',
  //   socialLink: 'https://www.instagram.com/supreme.luci/',
  // },
];

export const ogTeamData = [
  {
    id: 1,
    name: 't. “gustavo” Horace',
    designation: 'OG',
    image: '/assets/images/team/gustavo.svg',
    logo: '/assets/images/team/instagram.svg',
    socialLink: 'https://www.instagram.com/thousandways2grind/',
  },
  // {
  //   id: 2,
  //   name: 'BORIi BILLIONS',
  //   designation: 'Rapper',
  //   image: '/assets/images/team/teamImg9.svg',
  //   logo: '/assets/images/team/instagram.svg',
  //   socialLink: 'https://www.instagram.com/boriibillions___/',
  // },
  {
    id: 3,
    name: 'Moneyy marcc',
    designation: 'OG',
    image: '/assets/images/team/teamImg10.svg',
    logo: '/assets/images/team/instagram.svg',
    socialLink: 'https://www.instagram.com/moneyymarcc/',
  },
  // {
  //   id: 4,
  //   name: 'king savage',
  //   designation: 'OG/Rapper',
  //   image: '/assets/images/team/teamImg3.svg',
  //   logo: '/assets/images/team/instagram.svg',
  //   socialLink: 'https://www.instagram.com/manmansavageea/',
  // },
  {
    id: 5,
    name: 'G Fresh',
    designation: 'Rapper',
    image: '/assets/images/team/teamImg11.svg',
    logo: '/assets/images/team/instagram.svg',
    socialLink: 'https://www.instagram.com/gfresh100/',
  },
];

export const developmentData = [
  {
    id: 1,
    image: '/assets/images/development/dev1.svg',
    name: 'MUHAMMAD YUSUF',
    designation: 'Blockchain specialist',
  },
  {
    id: 2,
    image: '/assets/images/development/dev2.svg',
    name: 'Charles',
    designation: 'Game Development',
  },
  {
    id: 3,
    image: '/assets/images/development/dev3.svg',
    name: 'JB',
    designation: 'AI & Facial recognition',
  },
  {
    id: 4,
    image: '/assets/images/development/dev4.svg',
    name: 'SWAGERTY',
    designation: 'Creative mercenary',
  },
];

export const roadmapExploreData = [
  {
    id: 1,
    data: [
      'Gen 0 Advisors/OG’s selected',
      'Marketing/Community Manager',
      'Community Logo Contest',
      'Multi-Platinum Producer Javar Rockamore “Da Game” track',
      'Token Generation Event',
      'Gen 0 Mint',
      'Da Game Release',
    ],
  },
  {
    id: 2,
    data: [
      'Weekly In Game Competitions (Krowns) Eth Reward Pool',
      'King Pin of da Month (Kingpin Krowns) Custom NFT w/ likeness released in Gen 1 mint',
      'LA Kingpins Apparel Giveaway',
    ],
  },
  {
    id: 3,
    data: [
      'Philanthropy (See Mission)',
      'Treasury Fund Investment',
      'Da Game 2.0 Metaverse Development',
    ],
  },
  {
    id: 4,
    data: [
      'Original “Da Game” Mixtape NFT Airdrop (Javar Rockamore)',
      'Custom Merchandise',
      'Stickers and Apparel',
      'In Game OG Tipping',
      'IRL Da Game Events, Fashion Collabs, Pop Up’s',
    ],
  },
  {
    id: 5,
    data: [
      'Community Vote: New City',
      'Community Vote: Gen 1 mini-game',
      'New OG’s Selected',
      'Gen 1 Mint w/ King Pin of da Month’s included',
      'Da Dame 2.0 Metaverse Sneak Peaks',
    ],
  },
];
